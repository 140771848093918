import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";

//Development;
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };
//production;
const firebaseConfig = {
  apiKey: "AIzaSyAsdkdNKC2ze9iWEhzvenCCez-9KyenpWE",
  authDomain: "pharox-status-dashboard.firebaseapp.com",
  databaseURL: "https://pharox-status-dashboard.firebaseio.com",
  projectId: "pharox-status-dashboard",
  storageBucket: "pharox-status-dashboard.appspot.com",
  messagingSenderId: "701746382821",
  appId: "1:701746382821:web:adb1e40c419b087e",
  measurementId: "G-B554W96792",
};
const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");
firebase.initializeApp(firebaseConfig);
export { secondaryApp };
export default firebase;
