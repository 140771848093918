import {
  EditRounded,
  FingerprintOutlined,
  GroupOutlined,
  MailOutline,
} from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import React, { useState } from "react";
import styled from "styled-components";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { GroupsOutlined } from "@mui/icons-material";
import firebase, { secondaryApp } from "../../config/fbConfig";
import "firebase/firestore";
import { } from "firebase/auth";
import { setgroups } from "process";
import { fetchUsersList, getUserById } from "../../services/UserService";
import { setUsersList } from "../../store/actions/dataActions";
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";
import store from "../../store/store";
import { showErrorToast, showSuccessToast } from "../../utils/Toast";
function mapStateToProps(state: any) {
  return {
    userAddingMode: state.layout.userAddingMode,
    userEditingMode: state.layout.userEditingMode,
    modalState: state.layout.openUserModal,
    userToEdit: state.data.userToEdit,
  };
}

const FormAddingEditingUser = ({
  userAddingMode,
  userEditingMode,
  userToEdit,
}: any) => {
  // constants
  const USERTYPES = ["user", "admin", "viewer", "client"] as any;
  //! states
  const [selectedType, setSelectedType] = useState(
    (userEditingMode && userToEdit?.type) || ("user" as any)
  );
  const [firstName, setFirstName] = useState(
    (userEditingMode && userToEdit?.firstName) ||
    (userEditingMode && userToEdit?.name?.split(" ")[0]) ||
    ("" as any)
  );
  const [lastName, setLastName] = useState(
    (userEditingMode && userToEdit?.lastName) ||
    (userEditingMode && userToEdit?.name?.split(" ")[1]) ||
    ("" as any)
  );
  const [email, setEmail] = useState(
    (userEditingMode && userToEdit?.email) || ("" as any)
  );
  const [group, setGroup] = useState(
    (userEditingMode && userToEdit?.group) || ("" as any)
  );
  const [clientId, setClientId] = useState(
    (userEditingMode && userToEdit?.clientId) || ("" as any)
  );
  const [error, setError] = useState("" as any);
  const [loading, setLoading] = useState(false as any);
  //!handle submit
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (userEditingMode) {
      return editUser().then(() => showSuccessToast(
        "User edited",
        "bottom-center"
      )).catch(rej => showErrorToast(
        "Error: " + rej,
        "bottom-center"
      ));
    }

    // VALIDATION
    if (!selectedType) {
      return alert("user type is required.");
    }
    setLoading(true);
    try {
      //! create user with mail and password
      console.log('create user');
      let userCreated = await createUser();
      //! add user to firebase /users collection
      await addUser(userCreated);
      //! update usersList redux
      const usersList = await fetchUsersList(firebase.auth().currentUser.uid);
      setUsersList(usersList);
      //!inputs
      clearInputs();
      showSuccessToast(
        "User succesfully created",
        "bottom-center"
      )
    } catch (rej) {
      setLoading(false);
      showErrorToast(
        "Failed: " + rej,
        "bottom-center"
      );
    }
  };

  const rand = () => {
    return Math.random().toString(36).substr(2);
  }

  const token = () => {
    return rand() + rand();
  }

  //! create user auth
  const createUser = async () => {
    let createdUser;
    await secondaryApp
      .auth()
      .createUserWithEmailAndPassword(email, token())
      .then(async (user) => {
        createdUser = user;

        await firebase.firestore().collection('users').doc(user.user.uid).collection('roles').doc(store.getState().auth.loggedInCompany.companyId)
          .set({
            contrail_type: selectedType,
            groups: group ? [group] : null
          }, { merge: true });
        //adding to email_queue trigers cloud fn for mail
        firebase
          .firestore()
          .collection(`/customers/${store.getState().auth.loggedInCompany.companyId}/email_queue`)
          .add({
            mail: email,
            type: "setPassCode",
            userRef: firebase
              .firestore()
              .collection("users")
              .doc(user.user.uid),
          })
          .then(() => {
            console.log("password creation mail sent.");
          })
          .catch((e) => {
            console.log(e);
          });

        //newly created user logout
        secondaryApp.auth().signOut();
      })
      .catch((error) => {
        console.log(error);
      });

    return createdUser;
  };
  //! add user to firestore /users
  const addUser = async (user: any) => {
    const currentUser = await getUserById(firebase.auth().currentUser.uid);
    let res = await firebase
      .firestore()
      .collection("users")
      .doc(user.user.uid)
      .set({
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
        type: selectedType,
        smarti_admin: selectedType === "admin" ? true : false,
        smarti_viewer: selectedType === "viewer" ? true : false,
        smarti_user: selectedType === "user" ? true : false,
        smarti_client: selectedType === "client" ? true : false,
        clientId: clientId,
        platform: "smarti",
        related_customer: currentUser.related_customer,
        preffered_language: "en",
      });
    return res;
  };
  //!edit user
  const editUser = async () => {
    // VALIDATION
    if (!selectedType) {
      return alert("user type is required.");
    }
    setLoading(true);
    const currentUser = await getUserById(firebase.auth().currentUser.uid);

    let res = await firebase
      .firestore()
      .collection("users")
      .doc(userToEdit.id)
      .update({
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
        type: selectedType,
        smarti_admin: selectedType === "admin" ? true : false,
        smarti_viewer: selectedType === "viewer" ? true : false,
        smarti_user: selectedType === "user" ? true : false,
        smarti_client: selectedType === "client" ? true : false,
        platform: "smarti",
        related_customer: currentUser.related_customer,
        preffered_language: "en",
        clientId,
      });
    await firebase.firestore().collection('users').doc(userToEdit.id).collection('roles').doc(store.getState().auth.loggedInCompany.companyId)
      .set({
        contrail_type: selectedType,
        groups: group ? [group] : null
      }, { merge: true });
    //! update usersList redux
    const usersList = await fetchUsersList(firebase.auth().currentUser.uid);
    setUsersList(usersList);
    //!inputs
    clearInputs();
    return res;
  };
  //! clear inputs
  const clearInputs = () => {
    setLoading(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setGroup("");
    setError("");
  };
  return (
    <FormContainer>
      <Form onSubmit={(e: any) => handleFormSubmit(e)}>
        <Row>
          <PersonOutlineIcon />
          <Input
            onChange={(e: any) => setFirstName(e.target.value)}
            value={firstName}
            placeholder="first name"
            required
            type="text"
          />
        </Row>
        <Row>
          <PersonOutlineIcon />
          <Input
            onChange={(e: any) => setLastName(e.target.value)}
            value={lastName}
            placeholder="last name"
            required
            type="text"
          />
        </Row>
        <Row>
          <MailOutline />
          <Input
            disabled={userEditingMode && email ? true : false}
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
            placeholder="email adress"
            required
            type="email"
          />
        </Row>
        {error && <Error> {error.message} </Error>}
        <Row>
          <GroupsOutlined />
          <Input
            value={group}
            onChange={(e: any) => setGroup(e.target.value)}
            placeholder="assign to group"
            type="text"
          />
        </Row>
        <Row>
          <Label>user type</Label>
          <Select
            required
            value={selectedType}
            onChange={(e: any) => setSelectedType(e.target.value)}
          >
            {USERTYPES.map((type: any) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </Row>
        {/* client id */}
        {selectedType && selectedType === "client" && (
          <Row>
            <FingerprintOutlined />
            <Input
              required
              value={clientId}
              onChange={(e: any) => setClientId(e.target.value)}
              placeholder="client id/number"
              type="text"
            />
          </Row>
        )}

        <Button disabled={loading} type="submit">
          {loading && <CircularProgress size={24} />}
          {userAddingMode ? (
            <PersonAddIcon style={{ marginRight: "5px", padding: 0 }} />
          ) : (
            <EditRounded style={{ marginRight: "5px", padding: 0 }} />
          )}
          {userAddingMode ? "Add User" : "Edit User"}
        </Button>
      </Form>
    </FormContainer>
  );
};

export default connect(mapStateToProps)(FormAddingEditingUser);
const FormContainer = styled.div`
  overflow-y: hidden;
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.7rem 0.8rem;
  background-color: white;
  margin-bottom: 15px;
  width: 60%;
  color: gray;
  border-radius: 40px;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  margin-left: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
`;
const Label = styled.p`
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
`;
const Select = styled.select`
  margin-left: 15px;
  outline: none;
  width: 10rem;
  border: none;
  background-color: whitesmoke;
  padding: 0.6rem 1rem;
  border-radius: 40px;
  color: gray;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 40px;
  outline: none !important;
  padding: 1rem 1.4rem;
  width: 100%;
  max-width: 20rem;
  background-size: 200% auto;
  margin-top: 20px;
  transition: 0.5s all;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: gray;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &:hover {
    background-position: right center;
    transition: 0.5s;
    background-image: linear-gradient(
      to right,
      #ef32d99b 0%,
      #89fffdb2 51%,
      #ef32d9ae 100%
    );
  }
`;
const Error = styled.span`
  color: red;
  font-size: 14px;
  margin-top: -18px;
  margin-left: -20%;
  letter-spacing: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
